import { v4 as uuidv4 } from 'uuid'
import { type ApiError } from '/@src/assets/api'
import { ref, computed } from 'vue'
import { trim } from 'lodash-es'
import { openURL, useQuasar } from 'quasar'

interface RouteProps {
  initiative: string
  app: string
  dashboard?: string
  pageSet?: string
  page?: string
}

type Palette = {
  [key: string]: string
}

const palettes: Palette = {
  '#ffcccc': 'red-2',
  '#ff9999': 'red-3',
  '#ff6666': 'red-4',
  '#ff3333': 'red-5',
  '#ff0000': 'red-6',
  '#f50000': 'red-7',
  '#d60000': 'red-8',
  '#a30000': 'red-9',
  '#5c0000': 'red-10',
  '#ffe6cc': 'orange-2',
  '#ffcc99': 'orange-3',
  '#ffb366': 'orange-4',
  '#ff9933': 'orange-5',
  '#ff8000': 'orange-6',
  '#f57b00': 'orange-7',
  '#d66c00': 'orange-8',
  '#a35200': 'orange-9',
  '#5c2e00': 'orange-10',
  '#ffffcc': 'yellow-2',
  '#ffff99': 'yellow-3',
  '#ffff66': 'yellow-4',
  '#ffff33': 'yellow-5',
  '#ffff00': 'yellow-6',
  '#f5f500': 'yellow-7',
  '#d6d600': 'yellow-8',
  '#a3a300': 'yellow-9',
  '#5c5c00': 'yellow-10',
  '#ccffcc': 'green-2',
  '#99ff99': 'green-3',
  '#66ff66': 'green-4',
  '#33ff33': 'green-5',
  '#00ff00': 'green-6',
  '#00f500': 'green-7',
  '#00d600': 'green-8',
  '#00a300': 'green-9',
  '#005c00': 'green-10',
  '#ccffe6': 'light-green-2',
  '#99ffcc': 'light-green-3',
  '#66ffb3': 'light-green-4',
  '#33ff99': 'light-green-5',
  '#00ff80': 'light-green-6',
  '#00f57b': 'light-green-7',
  '#00d66c': 'light-green-8',
  '#00a352': 'light-green-9',
  '#005c2e': 'light-green-10',
  '#ccffff': 'cyan-2',
  '#99ffff': 'cyan-3',
  '#66ffff': 'cyan-4',
  '#33ffff': 'cyan-5',
  '#00ffff': 'cyan-6',
  '#00f5f5': 'cyan-7',
  '#00d6d6': 'cyan-8',
  '#00a3a3': 'cyan-9',
  '#005c5c': 'cyan-10',
  '#cce6ff': 'blue-2',
  '#99ccff': 'blue-3',
  '#66b3ff': 'blue-4',
  '#3399ff': 'blue-5',
  '#0080ff': 'blue-6',
  '#007bf5': 'blue-7',
  '#006cd6': 'blue-8',
  '#0052a3': 'blue-9',
  '#002e5c': 'blue-10',
  '#ccccff': 'indigo-2',
  '#9999ff': 'indigo-3',
  '#6666ff': 'indigo-4',
  '#3333ff': 'indigo-5',
  '#0000ff': 'indigo-6',
  '#0000f5': 'indigo-7',
  '#0000d6': 'indigo-8',
  '#0000a3': 'indigo-9',
  '#00005c': 'indigo-10',
  '#e6ccff': 'purple-2',
  '#cc99ff': 'purple-3',
  '#b366ff': 'purple-4',
  '#9933ff': 'purple-5',
  '#8000ff': 'purple-6',
  '#7b00f5': 'purple-7',
  '#6c00d6': 'purple-8',
  '#5200a3': 'purple-9',
  '#2e005c': 'purple-10',
  '#ffccff': 'pink-2',
  '#ff99ff': 'pink-3',
  '#ff66ff': 'pink-4',
  '#ff33ff': 'pink-5',
  '#ff00ff': 'pink-6',
  '#f500f5': 'pink-7',
  '#d600d6': 'pink-8',
  '#a300a3': 'pink-9',
  '#5c005c': 'pink-10',
  '#ffffff': 'grey-1',
  '#cdcdcd': 'grey-2',
  '#b2b2b2': 'grey-3',
  '#999999': 'grey-4',
  '#7f7f7f': 'grey-5',
  '#666666': 'grey-6',
  '#4c4c4c': 'grey-7',
  '#333333': 'grey-8',
  '#191919': 'grey-9',
  '#000000': 'grey-10',
}

export const makeRoute = ({ initiative, app, dashboard, pageSet, page }: RouteProps) => {
  let route = '/'
  route += initiative + '/'
  route += app + '/'
  if (!dashboard) {
    return route
  }
  route += dashboard + '/'
  if (!pageSet) {
    return route
  }
  route += pageSet + '/'
  if (!page) {
    return route
  }
  route += page
  return route
}

export const getPaletteFromHex = (hex: string | null | undefined) => {
  if (!hex) {
    return 'primary'
  }
  return palettes[hex as keyof Palette] || 'primary'
}

export const uidsAreEqual = (guid1: string, guid2: string) => {
  return uidStr(guid1) === uidStr(guid2)
}
export const uidStr = (guid: string) => {
  return (guid.includes('-') ? guid.split('-').join('') : guid).toLowerCase()
}
export const uuid = (): string => uidStr(uuidv4())
export const emptyGuid = '00000000000000000000000000000000'

// dom safe element id
export function rid(x?: string | null) {
  if (x && x.length) {
    if (x.startsWith('r_')) return x
    return `r_${uidStr(x)}`
  }
  return `r_${uuid()}`
}

export const notifyErr = (err: any, messageAlternate: string) => {
  if (isApiError(err) && err.body && err.body.Message) {
    const errorMessage = `${(err.body?.StatusCode as string | undefined | null) || '500'
      } - ${(err.body?.Message as string | undefined | null) || messageAlternate}`
    window.prompt(errorMessage)
    // Notify.create({
    //   type: 'negative',
    //   message: `${
    //     (err.body?.StatusCode as string | undefined | null) || '500'
    //   } - ${
    //     (err.body?.Message as string | undefined | null) || messageAlternate
    //   }`,
    // });
  }
}

export function extractRoutePathPortionFromPath(path: string) {
  const pathSegments = trim(
    path && path.length > 0 ? path : window.location.pathname,
    '/'
  ).split('/')
  const appDelimIndex = pathSegments.indexOf('-')
  return (
    appDelimIndex === -1 ? pathSegments : pathSegments.slice(0, appDelimIndex)
  ).join('/')
}

export function extractAppPathPortionFromPath(path: string) {
  const pathSegments = trim(
    path && path.length > 0 ? path : window.location.pathname,
    '/'
  ).split('/')
  const appDelimIndex = pathSegments.indexOf('-')
  return (appDelimIndex === -1 ? [] : pathSegments.slice(appDelimIndex + 1)).join('/')
}

export function getUploadUrl(appId: string, folderId: string) {
  return `${import.meta.env.VITE_API_BASE_URL}/api/apps/docs/${appId}/folders/${folderId}/files`;
}

export function getAvatarUrl(
  pic: string,
  asDownload: boolean = false,
  width: number = 40
) {
  return `${import.meta.env.VITE_API_BASE_URL}/avatars/${pic}?width=${width}${asDownload ? '&download=true' : ''}`
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFileUrl = (url: string, width: number = 0) => {
  return `${import.meta.env.VITE_API_BASE_URL}/${url}?${width ? '&width=' + width : ''}`
}

// function trim(str: string, ch: string) {
//   let start = 0,
//     end = str.length;

//   while (start < end && str[start] === ch)
//     ++start;

//   while (end > start && str[end - 1] === ch)
//     --end;

//   return (start > 0 || end < str.length) ? str.substring(start, end) : str;
// }

// function trimAny(str: string, chars: string[]) {
//   let start = 0,
//     end = str.length;

//   while (start < end && chars.indexOf(str[start]) >= 0)
//     ++start;

//   while (end > start && chars.indexOf(str[end - 1]) >= 0)
//     --end;

//   return (start > 0 || end < str.length) ? str.substring(start, end) : str;
// }

export interface SubmitEvent extends Event {
  /**
   * Returns the element representing the submit button that triggered the form submission, or null if the submission was not triggered by a button.
   */
  readonly submitter: HTMLElement | null
}

export interface IKeyIndexer extends ITypedKeyIndexer<any> { }
export interface ITypedKeyIndexer<T> {
  [key: string]: T
}
export interface IDictionary<TValue>
  extends Record<string, TValue | null | undefined>,
  ITypedKeyIndexer<TValue | null | undefined> { }
export interface IStringDictionary extends IDictionary<string> { }
export interface IObjectDictionary extends IDictionary<any> { }

export function isApiError(err: any): err is ApiError {
  return err && err.body && err.body.StatusCode ? true : false
}

interface ScriptDef {
  id?: string | null
  src: string
  crossorigin?: string | null
  integrity?: string | null
  replace?: boolean | null
  defer?: boolean | null
  async?: boolean | null
}

interface StyleDef {
  id: string | null
  href: string
  replace?: boolean | null
  // only use this when loading blob, href always otherwise
  src?: string | null
}

function isScriptLoadedByUrl(url: string) {
  return Boolean(document.querySelector(`script[src="${url}"]`))
}

function isStyleLoadedByUrl(url: string) {
  return Boolean(document.querySelector(`link[href="${url}"]`))
}

async function loadJsBlob(code: ScriptDef) {
  if (!code.src || !(code.src || '').trim()) {
    return
  }

  const blob = new Blob([code.src], { type: 'text/javascript' })
  const urlCreator = window.URL || window.webkitURL
  const url = urlCreator.createObjectURL(blob)

  await loadScript({ ...code, src: url })
}

async function loadCssBlob(code: StyleDef) {
  if (!code.src || !(code.src || '').trim()) {
    return
  }

  const blob = new Blob([code.src], { type: 'text/css' })
  const urlCreator = window.URL || window.webkitURL
  const url = urlCreator.createObjectURL(blob)

  await loadLink({ ...code, src: null, href: url })
}

function loadScript(url: string | ScriptDef): Promise<string> {
  return new Promise(function (resolve, reject) {
    let existing: HTMLElement | null = null
    const script = document.createElement('script')
    let surl = ''
    if (typeof url === 'string') {
      surl = url
    } else {
      surl = url.src
      if (url.id && url.id.length) {
        // make sure a matching id is not already loaded
        existing = document.getElementById(url.id)
        if (existing && !url.replace) {
          resolve(surl)
          return
        }
        script.id = url.id
      }
      script.async = url.async || false
      if (url.crossorigin && url.crossorigin.length)
        script.setAttribute('crossorigin', url.crossorigin)
      if (url.integrity && url.integrity.length)
        script.setAttribute('integrity', url.integrity)
    }
    script.src = surl
    // make sure it's not already loaded
    if (isScriptLoadedByUrl(surl)) {
      resolve(surl)
      return
    }
    script.onload = function () {
      setTimeout(() => {
        resolve(surl)
      }, 0)
    }
    script.onerror = function () {
      setTimeout(() => {
        reject(script.src)
      }, 0)
    }
    // remove existing matching id if it already exists
    if (existing) existing.remove()
    document.head.appendChild(script)
  })
}

function unloadScript(urlOrId: string) {
  if (!urlOrId) return

  let el = document.getElementById(urlOrId)
  if (!el) el = document.querySelector(`script[src="${urlOrId}"]`)
  if (el) el.remove()
}

function loadScripts(urls: string[]): Promise<string[]> {
  const promises: Promise<string>[] = []
  urls.forEach((url) => {
    promises.push(loadScript(url))
  })
  return Promise.all(promises)
}

function loadLink(url: string | StyleDef): Promise<string> {
  return new Promise(function (resolve, reject) {
    let existing: HTMLElement | null = null
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.media = 'all'
    let surl = ''
    if (typeof url === 'string') {
      surl = url
    } else {
      surl = url.href
      if (url.id && url.id.length) {
        // make a matching id is not already loaded
        existing = document.getElementById(url.id)
        if (existing && !url.replace) {
          resolve(surl)
          return
        }
        link.id = url.id
      }
    }
    link.href = surl
    // make sure it's not already loaded
    if (isStyleLoadedByUrl(surl)) {
      resolve(surl)
      return
    }
    link.onload = function () {
      setTimeout(() => {
        resolve(surl)
      }, 0)
    }
    link.onerror = function () {
      setTimeout(() => {
        reject(surl)
      }, 0)
    }
    // remove existing matching id if it already exists
    if (existing) existing.remove()
    document.head.appendChild(link)
  })
}

function unloadStyle(urlOrId: string) {
  if (!urlOrId) return

  let el = document.getElementById(urlOrId)
  if (!el) el = document.querySelector(`link[href="${urlOrId}"]`)
  if (el) el.remove()
}

function loadStyles(urls: string[]): Promise<string[]> {
  const promises: Promise<string>[] = []
  urls.forEach((url) => {
    promises.push(loadLink(url))
  })
  return Promise.all(promises)
}

function launchUrl(url: string): void {
  // if it's a full URL, including the scheme (eg: https://www.fema.gov), open the URL in a new tab;
  // otherwise, change the window's url which will recognize, which will
  // forgive the partial url path (eg: abc/def, or /abc/def)
  if (url && url.length > 4 && url.substring(0, 4) === 'http') openURL(url)
  else window.location.href = url
}

function isLocalhost() {
  return window.location.hostname === 'localhost'
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const logd = import.meta.env.VITE_DEV
  ? (message?: any, ...optionalParams: any[]) => console.log(message, optionalParams)
  : () => {
    return
  }

function addIfNotExistsImmutable<T>(arr: Array<T>, item: T): Array<T> {
  return arr.findIndex((a) => a === item) >= 0 ? [...arr] : [...arr, item]
}
function removeIfExistsImmutable<T>(arr: Array<T>, item: T): Array<T> {
  return [...arr.filter((a) => a !== item)]
}
function toggleInArrayImmutable<T>(arr: Array<T>, item: T): Array<T> {
  return arr.findIndex((a) => a === item) >= 0
    ? [...arr.filter((a) => a !== item)]
    : [...arr, item]
}

const delta = 6
function getMousePositionIndicator(
  div: HTMLElement,
  clientX: number,
  clientY: number
): string {
  const rect = div.getBoundingClientRect()
  const x = clientX - rect.left, // the relative mouse postion to the element
    y = clientY - rect.top, // ...
    w = rect.right - rect.left, // width of the element
    h = rect.bottom - rect.top // height of the element

  let c = '' // direction
  if (y < delta) c += 'n'
  // north
  else if (y > h - delta) c += 's' // south
  if (x < delta) c += 'w'
  // west
  else if (x > w - delta) c += 'e' // east

  return c && c.length ? `${c}-indicator` : 'no-indicator'
}

function mousePointerIsInDropTargetContainer(
  div: HTMLElement,
  clientX: number,
  clientY: number
): boolean {
  const rect = div.getBoundingClientRect()
  const top = rect.top
  const bottom = window.screen.availHeight - rect.bottom
  const left = rect.left
  const right = window.screen.availWidth - rect.right
  const isInDropTarget =
    clientX <= right && clientX >= left && clientY >= top && clientY <= bottom

  return isInDropTarget
}

function temporaryClassesManager(initialClasses: string[]) {
  const _initialClasses = [...initialClasses]
  const extraClasses = ref<Array<string>>([])
  const allClasses = computed<Array<string>>(() => {
    return [..._initialClasses, ...extraClasses.value]
  })
  function addClasses(...classes: Array<string>) {
    const newClasses = classes.filter((c) => !extraClasses.value.includes(c))
    if (newClasses.length) extraClasses.value.push(...newClasses)
  }
  function removeClasses(...classes: Array<string>) {
    extraClasses.value = extraClasses.value.filter((x) => !classes.includes(x))
  }
  function resetClasses(...defaultClasses: Array<string>) {
    extraClasses.value = [...defaultClasses]
  }
  return { allClasses, resetClasses, addClasses, removeClasses }
}

// https://vanoneang.github.io/article/v-model-in-vue3.html#turn-it-into-a-composable
function useModelWrapper(
  props: any,
  emit: (action: string, payload?: any | null) => void,
  name = 'modelValue'
) {
  return computed({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  })
}

function getScrollTarget(el: HTMLDivElement) {
  return el
}
function getScrollHeight(el: HTMLDivElement) {
  return el.scrollTop
}
function setVerticalScrollPosition(
  el: HTMLDivElement,
  height: number,
  increment: number
) {
  el.scrollTop = height + increment
}
function getCssVar(variableName: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(
    variableName.startsWith('--') ? variableName : `--${variableName}`
  )
}

const take = (
  input: string | undefined | null,
  numberOfCharacters: number,
  trailingString: string | undefined | null = '...'
) => {
  if (!input || input.length < numberOfCharacters) return input
  return input.substring(0, numberOfCharacters) + trailingString
}

export {
  getMousePositionIndicator,
  temporaryClassesManager,
  loadJsBlob,
  loadCssBlob,
  loadScript,
  loadScripts,
  loadLink,
  loadStyles,
  unloadScript,
  unloadStyle,
  launchUrl,
  isLocalhost,
  logd,
  addIfNotExistsImmutable,
  removeIfExistsImmutable,
  toggleInArrayImmutable,
  useModelWrapper,
  getScrollTarget,
  getScrollHeight,
  setVerticalScrollPosition,
  getCssVar,
  take,
  mousePointerIsInDropTargetContainer,
}

export type { ScriptDef, StyleDef }
